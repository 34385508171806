<template>
    <div>
        <Quote/>
        <GoBack next="Modul qo'shish"/>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-10 col-md-8">
                <form class="ABC" @submit.prevent="courseAdd">
                    <!--  Select course-type -->
                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">Kurs turini tanlang</label>
                        <b-form-select
                            name="hero"
                            class="form-control"
                            id="exampleInputId1"
                            v-model="addCourse.type"
                            required
                        >
                            <option
                                v-for="courses of getCourseTypes"
                                :key="courses.id"
                                :label="courses.name"
                                :value="'/api/course_types/' + courses.id"
                            ></option>
                        </b-form-select>
                    </div>

                    <!--  Select teacher  -->
                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">O'qituvchini tanlang</label>
                        <b-form-select
                            name="hero"
                            class="form-control"
                            id="exampleInputId1"
                            v-model="teacher.id"
                        >
                            <option
                                v-for="teacher in getTeachersList"
                                :key="teacher.id"
                                :label="teacher.givenName + ' ' + teacher.familyName"
                                :value="teacher.id"
                            ></option>
                        </b-form-select>
                    </div>

                    <!--  Select course -->
                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">Kursni tanlang</label>
                        <b-form-select
                            name="hero"
                            class="form-control"
                            id="exampleInputId1"
                            v-model="addCourse.module"
                            required
                        >
                            <option
                                v-for="course in getModules"
                                :key="course.id"
                                :label="course.name"
                                :value="'/api/modules/' + course.id"
                            ></option>
                        </b-form-select>
                    </div>

                    <!--  Enter module-name -->
                    <div class="mb-3">
                        <label-component
                            label-for="exampleInputEmail1"
                            class="mb-1"
                        >
                            Modul nomini kiriting
                        </label-component>
                        <input-component
                            model-name="name"
                            type="text"
                            id="exampleInputEmail1"
                            :model-form="addCourse"
                            required
                        />
                    </div>

                    <!--  Enter module-price -->
                    <div class="mb-3">
                        <label for="exampleInputId4" class="form-label">Modul narxini kiriting</label>
                        <b-form-input
                            v-model="addCourse.price"
                            :formatter="formatNumber"
                            required
                        >
                        </b-form-input>
                    </div>

                    <!--  Select start-month -->
                    <div class="mb-3">
                        <label for="exampleInputId3" class="form-label">Qaysi oydan boshlanadi?</label>
                        <b-form-select
                            class="form-control"
                            id="exampleInputId3"
                            v-model="addCourse.startMonth"
                            required
                        >
                            <option
                                v-for="month of getMonths.models"
                                :key="month.id"
                                :value="month.id"
                            > {{ month.name }}
                            </option>
                        </b-form-select>
                    </div>

                    <!--  Select start-month -->
                    <div class="mb-3">
                        <label for="exampleInputId4" class="form-label">Telegram guruhi linkini kiriting</label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputId5"
                            v-model="addCourse.link"
                        >
                    </div>

                    <!--  Enter count-students -->
                    <div class="mb-3">
                        <label-component
                            label-for="count"
                            class="mb-1"
                        >
                            Joylar soni
                        </label-component>
                        <input-component
                            model-name="countOfStudents"
                            type="number"
                            id="count"
                            :model-form="addCourse"
                            required
                        />
                    </div>

                    <div class="d-flex justify-content-end">
                        <div class="row mb-3 mt-3 col-12 col-md-5 col-lg-4 col-xl-3">

                            <button-component>Saqlash</button-component>
                        </div>
                    </div>
                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Kurs muvaffaqiyatli qo'shildi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>
            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import {mapActions, mapGetters} from "vuex";
import accessMixin from "@/mixin/accessMixin";
import ButtonComponent from "@/components/ButtonComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import InputComponent from "@/components/InputComponent.vue";

export default {
    name: "Add-CoursePage",
    mixins: [accessMixin],
    components: {ButtonComponent, GoBack, Quote, InputComponent, LabelComponent},
    data() {
        return {
            addCourse: {
                type: '',
                startMonth: '',
                countOfStudents: '',
                name: '',
                price: '',
                module: '',
                link: '',
            },
            show: true,
            currentPage: 1,
            teacher: {
                id: null
            },
        }
    },
    computed: {
        ...mapGetters(['getCourseTypes', 'getMonths', 'getTeachersList', 'getModules']),
    },
    methods: {
        ...mapActions(['fetchAddCourse', 'fetchCourseTypes', 'fetchMonth', 'fetchTeachers', 'fetchModules']),
        courseAdd() {
            let price = this.addCourse.price + '00'
            price.replace(/\s/g, "")
            console.log(this.teacher.id, 'bu id')
            this.fetchAddCourse({
                type: this.addCourse.type,
                startMonth: '/api/months/' + this.addCourse.startMonth,
                countOfStudents: parseInt(this.addCourse.countOfStudents),
                name: this.addCourse.name,
                module: this.addCourse.module,
                price: parseInt(price.replace(/\s/g, "")),
                teacher: this.teacher.id ? '/api/users/' + this.teacher.id : this.teacher.id,
                link: this.addCourse.link,
            })
                .then(() => {
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                })
                .catch(() => {
                    console.log('addCourse da xato')
                })
        },
        formatNumber(n) {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$router.push('/cabinet')
        }

    },
    mounted() {
        this.show = true
        this.fetchCourseTypes()
        this.fetchMonth()
        this.fetchModules()
        this.fetchTeachers(this.currentPage)
        this.show = false
    }
}
</script>

<style scoped>

* {
    padding: 0;
    margin: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

section form {
    font-family: Comfortaa, cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
    padding-top: 8px;
    padding-bottom: 8px;
}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}
</style>
